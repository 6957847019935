import React from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import { GenerateLink } from "../utils/generateLinks";

const FooterWrapper = tw.section`
bg-primary text-white py-16 font-sans
`;

const FooterContainer = tw.div`
container grid md:grid-cols-3 text-sm
`;

const MenuWrapper = tw.div`
flex flex-col text-sm mb-8 lg:mb-0
`;

const ContactArea = tw.div`
flex flex-col py-6
`;

const ContactText = tw.p`
ml-3
`;

const ContactMethod = tw.div`
  flex text-sm font-bold items-center mb-6 font-sans
`;

const SectionHeader = tw.p`
  font-bold mb-3
`;

const CertificationArea = tw.div`
  grid grid-cols-2 gap-4
  `;

const CertificationImage = tw.img`
  w-24
  `;

const CertificationLink = tw.a`
    flex items-center justify-center
  `;

export let Footer = () => {
  const query = useStaticQuery(graphql`
    query GetFooter {
      sanityMenu {
        item {
          _key
          label
          childpages {
            slug {
              current
              _type
            }
          }
        }
      }
      sanityCompany {
        address1
        address2
        city
        country
        email
        name
        phone
        postcode
        certifications {
          image {
            asset {
              url
            }
          }
          pdf {
            asset {
              url
            }
          }
        }
      }
    }
  `);

  const P = tw.p`font-sans leading-normal pb-2`;

  const {
    sanityCompany: {
      address1,
      address2,
      city,
      country,
      email,
      phone,
      postcode,
      name,
      certifications,
    },
    sanityMenu,
  } = query;
  return (
    <FooterWrapper>
      <FooterContainer>
        <MenuWrapper>
          <SectionHeader>Menu</SectionHeader>
          {sanityMenu.item.map(({ label, childpages, _key }) => (
            <GenerateLink
              theme={"dark"}
              link={childpages[0].slug}
              NoPadding={true}
              key={_key}
            >
              {label}
            </GenerateLink>
          ))}
        </MenuWrapper>
        <div>
          <SectionHeader>Address</SectionHeader>
          <P>{name}</P>
          <P>{address1}</P>
          <P>{address2}</P>
          <P>{city}</P>
          <P>{postcode}</P>
          <P>{country}</P>
        </div>
        <ContactArea>
          <ContactMethod>
            <FontAwesomeIcon icon={faPhoneAlt} />
            <ContactText>{phone}</ContactText>
          </ContactMethod>
          <ContactMethod>
            <FontAwesomeIcon icon={faEnvelope} />
            <ContactText>{email}</ContactText>
          </ContactMethod>
          <CertificationArea>
            {certifications.map((cert) => (
              <CertificationLink
                href={cert.pdf.asset.url}
                rel="nofollow"
                target="_blank"
              >
                <CertificationImage src={cert.image.asset.url} alt="" />
              </CertificationLink>
            ))}
          </CertificationArea>
        </ContactArea>
      </FooterContainer>
    </FooterWrapper>
  );
};
